import db from './db';

export const getPendingInvitation = async (coId) => {
  try {
    let usersInvitationsRef = db.collection("usersInvitations");
    const usersInvitationsSnapshot = await usersInvitationsRef
      .where("companyId", "==", coId)
      .get();

    if (usersInvitationsSnapshot.empty) {
      return [];
    }

    const invitationDocs = usersInvitationsSnapshot.docs;
    const invitations = invitationDocs.map((doc) => doc.data());

    return invitations;
  } catch (error) {
    console.error("Error getting pending invitations: ", error);
    return;
  }
};

export const checkInvitations = async (email) => {
  try {
    let userInvitationsRef = db.collection("usersInvitations");
    let userInvitationsSnapshot = await userInvitationsRef
      .where("email", "==", email)
      .get();
    if (!userInvitationsSnapshot.empty) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const checkInvitationsAndUpdate = async (email, user) => {
  try {
    let userInvitationsRef = db.collection("usersInvitations");
    let userInvitationsSnapshot = await userInvitationsRef
      .where("email", "==", email)
      .get();
    let userCompaniesRef = db.collection("usersCompanies").doc(user.uid);
    let userCompaniesDoc = await userCompaniesRef.get();
    let userCompaniesData = userCompaniesDoc.data();

    if (!userInvitationsSnapshot.empty) {
      const companyIdsToAdd = userInvitationsSnapshot.docs.map(
        (invitationDoc) => invitationDoc.data().companyId
      );
      await userCompaniesRef.update({
        companies: [...userCompaniesData?.companies, ...companyIdsToAdd],
      });

      const invitationIdsToRemove = userInvitationsSnapshot.docs.map(
        (invitationDoc) => invitationDoc.id
      );

      for (const invitationId of invitationIdsToRemove) {
        await userInvitationsRef.doc(invitationId).delete();
      }
    }

    return;
  } catch (error) {
    console.error("Error checking invitations: ", error);
    throw "Error No Invitation";
  }
};
