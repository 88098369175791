import db from './db';

export const createReport = async (name, coId, reportSettings, hasBudget) => {
  try {
    if (!name || !coId || !reportSettings) {
      throw "missing data";
    }
    let newReport = await db.collection("reports").add({
      name: name,
      companyId: coId,
      createdAt: Date.now(),
      visible: true,
      settings: reportSettings,
      hasBudget: hasBudget,
    });
    return newReport;
  } catch (error) {
    console.error("Error adding sales by products record: ", error);
    return;
  }
};

export const getReports = async (coId) => {
  try {
    const reportsRef = db.collection("reports");
    const reports = await reportsRef.where("companyId", "==", coId).get();
    let reportsData = [];
    reports.forEach((doc) => {
      if (doc.data().visible) {
        reportsData.push({
          id: doc.id,
          ...doc.data(),
        });
      }
    });

    // order reportsData by createdAt
    reportsData.sort((a, b) => {
      return b.createdAt - a.createdAt;
    });
    return reportsData;
  } catch (error) {
    console.error("Error getting reports: ", error);
    return;
  }
};

export const getAllReports = async () => {
  try {
    const reportsRef = db.collection("reports");
    const reports = await reportsRef.get();
    let reportsData = [];
    reports.forEach((doc) => {
      reportsData.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return reportsData;
  } catch (error) {
    console.error("Error getting reports: ", error);
    return;
  }
};

export const getProposal = async (id) => {
  try {
    let pRef = db.collection("proposals").doc(id);
    let pData = await pRef.get();
    return pData.data();
  } catch (error) {
    console.error("error getting proposal: ", error);
    return;
  }
};
