import db from './db';
import { getUser } from './users';
import { getCompanyById } from './companies';

export const getCompanyMembers = async (coId) => {
  try {
    let usersCompaniesRef = db.collection("usersCompanies");
    const usersCompaniesSnapshot = await usersCompaniesRef
      .where("companies", "array-contains", coId)
      .get();
    const companyDetails = await getCompanyById(coId);

    const usersPromises = [];

    for (const doc of usersCompaniesSnapshot.docs) {
      const user = await getUser(doc.id);
      const userRole =
        doc.id === companyDetails?.adminAuthId ? "owner" : "member";
      usersPromises.push({ ...user, id: doc.id, role: userRole });
    }

    const users = await Promise.all(usersPromises);
    return users;
  } catch (error) {
    console.error("Error getting members: ", error);
    return;
  }
};

export const inviteTeamMember = async (memberEmail, coId) => {
  try {
    let userRef = db.collection("users");
    let userData = await userRef.where("email", "==", memberEmail).get();
    let userInvitationsRef = db.collection("usersInvitations");

    // Non-registered users
    if (userData.empty) {
      // Check if the user is already invited to the specific company
      const existingInvitation = await userInvitationsRef
        .where("email", "==", memberEmail)
        .where("companyId", "==", coId)
        .get();

      if (existingInvitation.empty) {
        await userInvitationsRef.add({
          email: memberEmail,
          companyId: coId,
        });
      } else {
        throw new Error("User is already invited to this company.");
      }
      return;
    }

    let userId = userData.docs[0].id;

    let userCompaniesRef = db.collection("usersCompanies").doc(userId);
    let userCompaniesSnapshot = await userCompaniesRef.get();

    // Registered users
    if (!userCompaniesSnapshot.exists) {
      await userCompaniesRef.set({
        companies: [coId],
      });
    } else {
      // Check if the company is already added before updating
      let userCompaniesData = userCompaniesSnapshot.data();
      if (!userCompaniesData.companies.includes(coId)) {
        await userCompaniesRef.update({
          companies: [...userCompaniesData.companies, coId],
        });
      } else {
        throw new Error("User is already added to this company");
      }
    }

    return userId;
  } catch (error) {
    console.error("Error inviting member: ", error);
    throw error;
  }
};

export const removeMember = async (memberId, coId) => {
  try {
    let userCompaniesRef = db.collection("usersCompanies").doc(memberId);
    let userCompaniesSnapshot = await userCompaniesRef.get();

    await userCompaniesRef.set({
      companies: userCompaniesSnapshot
        .data()
        .companies.filter((i) => i !== coId),
    });

    return;
  } catch (error) {
    console.error("Error remove member: ", error);
    return;
  }
};

export const transferOwnership = async (memberId, coId) => {
  try {
    let userCompaniesRef = db.collection("companies").doc(coId);

    await userCompaniesRef.update({
      adminAuthId: memberId,
    });

    return;
  } catch (error) {
    console.error("Error transferring ownership: ", error);
  }
};
