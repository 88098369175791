import db from './firestore/db';
export const firestore = db;

export {
  getNotesByCompanyId,
  updateNote,
  addNote
} from './firestore/notes';

export {
  addUser,
  addInvitedUser,
  getUser,
  getUserByEmail,
  getUserCompanies
} from './firestore/users';

export {
  updateCompany,
  upsertCompanyToken,
  updateCompanyDetailsById,
  updateCompanyPreferencesById,
  getAllCompanies,
  getCompanyById,
  addCompany
} from './firestore/companies';

export {
  createReport,
  getReports,
  getAllReports,
  getProposal
} from './firestore/reports';

export {
  getCompanyMembers,
  inviteTeamMember,
  removeMember,
  transferOwnership
} from './firestore/team';

export {
  getPendingInvitation,
  checkInvitations,
  checkInvitationsAndUpdate
} from './firestore/invitations';
