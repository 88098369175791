import db from './db';

export const getNotesByCompanyId = async (companyId) => {
  try {
    const notesRef = db.collection("notes");
    const snapshot = await notesRef.where("companyId", "==", companyId).get();
    if (snapshot.empty) {
      console.log("No matching notes found.");
      return [];
    }
    let notes = [];
    snapshot.forEach((doc) => {
      notes.push({ id: doc.id, ...doc.data() });
    });
    return notes;
  } catch (error) {
    console.error("Error getting notes by company ID: ", error);
    throw error;
  }
};

export const updateNote = async (noteId, noteDetails) => {
  try {
    const noteRef = db.collection("notes").doc(noteId);
    await noteRef.update({
      ...noteDetails,
      updatedAt: Date.now(),
    });
    return noteId;
  } catch (error) {
    console.error("Error updating note: ", error);
    throw error;
  }
};

export const addNote = async (noteDetails) => {
  try {
    const noteRef = db.collection("notes");
    const newNote = await noteRef.add({
      ...noteDetails,
      createdAt: Date.now(),
    });
    return newNote.id;
  } catch (error) {
    console.error("Error adding note: ", error);
    throw error;
  }
};
