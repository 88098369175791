import { init } from 'next-firebase-auth'
import firebase from './utils/firebase'

//firebaseAuthEmulatorHost: 'localhost:9099',
const initAuth = () => {
  console.log('init')
  init({
    onVerifyTokenError: () => {},
    onTokenRefreshError: () => {},
    authPageURL: '/login',
    appPageURL: '/reports',
    loginAPIEndpoint: '/api/login',
    logoutAPIEndpoint: '/api/logout',
    firebaseAdminInitConfig: {
      credential: {
        projectId: 'pinto-financial',
        clientEmail: 'firebase-adminsdk-zei60@pinto-financial.iam.gserviceaccount.com',
        // The private key must not be accesssible on the client side.
        privateKey: (process.env.FIREBASE_PRIVATE_KEY ? JSON.parse(process.env.FIREBASE_PRIVATE_KEY) : undefined),
      },
      databaseURL: 'https://pinto-financial.firebaseio.com',
    },
    firebaseClientInitConfig: {
      apiKey: 'AIzaSyAZa1FUEJrAO0j8ZL8ft-6FwNTh4ob-4hg', // required
      //authDomain: 'pinto-financial.firebaseapp.com',
      //databaseURL: 'https://pinto-financial.firebaseio.com',
      //projectId: 'pinto-financial',
    },
    cookies: {
      name: 'PintoFinancial', // required
      // Keys are required unless you set `signed` to `false`.
      // The keys cannot be accessible on the client side.
      keys: [
        process.env.COOKIE_SECRET_CURRENT,
        process.env.COOKIE_SECRET_PREVIOUS,
      ],
      httpOnly: true,
      maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
      overwrite: true,
      path: '/',
      sameSite: 'strict',
      secure: true, // set this to false in local (non-HTTPS) development
      signed: true,
    }
  })
}

export default initAuth
