import db from './db';

export const updateCompany = async (coId, data) => {
  try {
    let companyRef = await db.collection("companies").doc(coId);
    let setWithMerge = await companyRef.set({ ...data }, { merge: true });
    return;
  } catch (error) {
    console.error("error adding user: ", error);
    return;
  }
};

export const upsertCompanyToken = async (token, realmId, coId) => {
  try {
    let companyRef = await db.collection("companies").doc(coId);
    let setWithMerge = companyRef.set(
      { token, realmId: realmId },
      { merge: true }
    );
    return;
  } catch (error) {
    console.error("error adding user: ", error);
    return;
  }
};

export const updateCompanyDetailsById = async (coId, data) => {
  try {
    let companyRef = db.collection("company-details").doc(coId);
    data.CompanyId = coId;
    data.CreatedAt = Date.now();
    let setWithMerge = companyRef.set(data, { merge: true });
    return;
  } catch (error) {
    console.error("error updating company details: ", error);
    throw "error";
  }
};

export const updateCompanyPreferencesById = async (coId, data) => {
  try {
    let companyRef = db.collection("company-preferences").doc(coId);
    data.CompanyId = coId;
    data.CreatedAt = Date.now();
    let setWithMerge = companyRef.set(data, { merge: true });
    return;
  } catch (error) {
    console.error("error updating company preferences: ", error);
    throw "error";
  }
};

export const getAllCompanies = async () => {
  try {
    let companiesRef = db.collection("companies");
    let companiesData = await companiesRef.get();
    const tempCo = companiesData.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    return tempCo;
  } catch (error) {
    console.error("Could Not Get All Companies: ", error);
    return;
  }
};

export const getCompanyById = async (coId) => {
  try {
    let companyRef = db.collection("companies").doc(coId);
    let companyData = await companyRef.get();
    return { ...companyData.data(), id: coId };
  } catch (error) {
    console.error("Could Not Find Company: ", error);
    return;
  }
};

export const addCompany = async (accountantId, companyName) => {
  try {
    // Create new company
    let newCompany = await db.collection("companies").add({
      name: companyName,
      adminAuthId: accountantId,
      createdAt: Date.now(),
      isAccountantFirm: false
    });

    // Add company to accountant's companies list
    let accountantCompaniesRef = db.collection("usersCompanies").doc(accountantId);
    let accountantCompaniesSnapshot = await accountantCompaniesRef.get();

    if (!accountantCompaniesSnapshot.exists) {
      await accountantCompaniesRef.set({
        companies: [newCompany.id]
      });
    } else {
      await accountantCompaniesRef.update({
        companies: [...accountantCompaniesSnapshot.data().companies, newCompany.id]
      });
    }

    return newCompany.id;
  } catch (error) {
    console.error("Error adding company:", error);
    throw error;
  }
};
