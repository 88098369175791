import db from './db';
import { getCompanyById } from './companies';

export const addUser = async (user, business, name, isAccountant = false) => {
  try {
    let newUser = await db.collection("users").doc(user.uid);
    await newUser.set(
      {
        email: user.email,
        name: name,
        accountant: isAccountant
      },
      { merge: true }
    );

    let newCompany = await db.collection("companies").add({
      name: business,
      adminAuthId: user.uid,
      createdAt: Date.now(),
      isAccountantFirm: isAccountant
    });

    let newUserCompany = db.collection("usersCompanies").doc(user.uid);
    await newUserCompany.set(
      {
        companies: [newCompany.id],
      },
      { merge: true }
    );

    let setWithMerge = await newUser.set(
      {
        companyId: newCompany.id,
      },
      { merge: true }
    );
    return;
  } catch (error) {
    console.error("error adding user: ", error);
    return;
  }
};

export const addInvitedUser = async (user, name) => {
  try {
    let newUser = await db.collection("users").doc(user.uid);
    await newUser.set(
      {
        email: user.email,
        name: name,
      },
      { merge: true }
    );

    let newUserCompany = db.collection("usersCompanies").doc(user.uid);
    await newUserCompany.set(
      {
        companies: [],
      },
      { merge: true }
    );
    return;
  } catch (error) {
    console.error("error adding user: ", error);
    return;
  }
};

export const getUser = async (userId) => {
  try {
    let userRef = db.collection("users").doc(userId);
    let userData = await userRef.get();
    return userData.data();
  } catch (error) {
    console.error("error getting user: ", error);
    return;
  }
};

export const getUserByEmail = async (userEmail) => {
  try {
    let userRef = db.collection("users");
    let userData = (await userRef.where("email", "==", userEmail).get())
      .docs[0];
    return { ...userData.data(), id: userData.id };
  } catch (error) {
    console.error("error getting user: ", error);
    return;
  }
};

export const getUserCompanies = async (user) => {
  try {
    if (!user) {
      return [];
    }

    if (user.email.endsWith("@pintofinancial.com")) {
      // For Pinto Financial staff, show all companies
      let companiesRef = db.collection("companies");
      let companiesData = await companiesRef.get();
      const tempCo = companiesData.docs.map((doc) => {
        return getCompanyById(doc.id);
      });
      const allCos = await Promise.all(tempCo);
      return allCos.map((co) => {
        return { id: co.id, name: co.name };
      });
    } else if (user.accountant) {
      // For accountants, get companies where they are the adminAuthId and isAccountantFirm is false
      let companiesRef = db.collection("companies");
      let companiesData = await companiesRef
        .where("adminAuthId", "==", user.uid)
        .where("isAccountantFirm", "==", false)
        .get();
      
      return companiesData.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name
      }));
    } else {
      // For regular users, get their associated companies
      let userCompaniesRef = db.collection("usersCompanies").doc(user.id);
      const userCompaniesSnapshot = await userCompaniesRef.get();
      
      if (!userCompaniesSnapshot.exists || !userCompaniesSnapshot.data()?.companies) {
        return [];
      }

      const allCompanyData = [];
      for (const company of userCompaniesSnapshot.data().companies) {
        const companyData = await getCompanyById(company);
        if (companyData) {
          allCompanyData.push({
            name: companyData.name,
            id: companyData.id,
          });
        }
      }
      return allCompanyData;
    }
  } catch (error) {
    console.error("Error getting companies: ", error);
    return [];
  }
};
